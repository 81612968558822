.chakra-form-control > .css-r6z5ec {
  width: 100% !important;
}
.chakra-form-control > .chakra-button {
  padding-right: 14px !important;
  font-weight: 400;
}
.age-wrapper {
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
}
.age-wrapper > .chakra-input {
  border: none;
}
